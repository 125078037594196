.nav-bar {
  background: #181818;
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  min-height: 500px;

  .logo {
    display: block;
    padding: 8px 0;

    img {
      display: block;
      margin: 8px auto;
      width: 40px;
      height: auto;

      &.sub-logo {
        width: 50px;
      }
    }
  }

  nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 210px;
    top: 50%;
    margin-top: -120px;
    width: 100%;

    a {
      font-size: 22px;
      color: #4d4d4e;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #ffd700;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        font-size: 10px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
      }

      &:first-child {
        &:after {
          content: 'HOME';
        }
      }
    }
      a.home-link {
      &:after {
        content: 'HOME';
      }
    }
    a.about-link {
      &:after {
        content: 'ABOUT';
      }
    }
    a.projects-link {
      &:after {
        content: 'PROJECTS';
      }
    }
    a.contact-link {
      &:after {
        content: 'CONTACT';
      }
    }
    a.linked-link {
      &:after {
        content: 'LINKEDIN';
      }
    }
    a.github-link {
      &:after {
        content: 'GITHUB';
      }
    }
    a.active {
      svg {
        color: #ffd700;
      }
    }
  }

  ul {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
      a {
        padding: 7px 0;
        display: block;
        font-size: 15px;
        line-height: 16px;
        color: #4d4d4e;
      }

      &:hover {
        .anchor-icon {
          color: #ffd700;
        }
      }
    }
  }
}

a.linked-link:hover {
  color: #ffd700;
}

.logo_subtitle {
  color: #ffd700;
  font-size: 15px;
  text-align: center;
  opacity: 80%;
}

@media screen and (max-width: 1200px) {
  .nav-bar { 
    background: transparent;
    position: initial;
    height: auto;
    min-height: auto;
    z-index: 2;

    nav {
      display: grid;
      grid-template-columns: 75px 82px 113px 103px;
      float: right;
    }
    nav {
      width: 100%;
      top: 0;
      left: 0;
      background: #181818;
      height: 50px;
      margin: 0;
      z-index: 2;
      position: fixed;

        &.mobile-show {
          display: block;
        }
        a {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        a:after {
          opacity: 1;
          position: initial;
          width: auto;
          margin-left: 10PX;
        }
        a svg {
          opacity: 1 !important;
        }
     }

    .logo {
     padding: 0;
     z-index: 6;
    }
    .logo_subtitle {
     display: none;
    }

    .fa-house,
    .fa-user,
    .fa-envelope,
    .fa-gear,
    .fa-linkedin,
    .linked-link,
    .github-link {
       display: none;
     }

     a.home-link:after,
     a.about-link:after,
     a.projects-link:after,
     a.contact-link:after {
      font-size: 15px;
      color: #ffd700;
      padding: 0;
     }
  }
}
