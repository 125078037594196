.about-page {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 510px 1fr;
}

.text-zone {
  position: absolute;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);
  width: 48%;
  max-height: 90%;
  z-index: 5;
}

.slide-zone {
  margin-left: 0;
  margin-right: 30px;
  grid-column-start: 2;
  display: grid;
  grid-template-rows: 30% auto 30%;
}

.slider {
  background-color: #022c43;
  max-width: 100%;
  height: 300px;
  width: 400px;
  margin: auto;
}

.slider .MuiPaper-root {
  background-color: #022c43;
  max-height: 300px;
}

.slideImg {
  border-radius: 10px;
}

.Im_the_greatest p.sub_title {
  font-family: 'Titillium Web', Tahoma, Verdana, Segoe, sans-serif;
  font-size: 19px;
}

div.Im_the_greatest p.brighter {
  color: #ffd700;
  font-family: "Coolvetica";
  font-size: 24px;
  font-weight: 200;
}
.Im_the_greatest p.favs_title {
  font-family: 'Titillium Web', Tahoma, Verdana, Segoe, sans-serif;
  font-size: 19px;
  margin-top: 0;
}

ul.topic_list {
  font-family: 'Titillium Web', Tahoma, Verdana, Segoe, sans-serif;
  list-style: none;
  padding-left: 0;
}
ul.topic_list li {
  position: relative;
  padding-left: 2.5em;
}
ul.topic_list li:before {
  content: '\2713';  /* placeholder for the SVG */
  position: absolute;
  left: 0;  /* place the SVG at the start of the padding */
  width: 3em;
  height: 3em;
  background-color: #edf2f7;
  background: url("data:image/svg+xml;utf8,<?xml version='1.0' encoding='utf-8'?><svg width='18' height='18' fill='limegreen' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'><path d='M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z'/></svg>") no-repeat;
}
.container .text-zone ul.topic_list li p {
  font-family: 'Titillium Web', Tahoma, Verdana, Segoe, sans-serif;
}
.container .text-zone td {
  font-family: 'Titillium Web', Tahoma, Verdana, Segoe, sans-serif;
  font-size: 17px;
  font-weight: 200;
  text-align: center;
}

.favs_box {
  padding: 25px;
  font-size: 13px;
}
.favs_box_wrapper {
  background-color: #303030;
  border-radius: 10px;
  border: 1px solid rgb(87, 87, 87);
  max-width: 398px;
  margin: 35px auto 35px auto;
}

.BrightCyan {
  color: #00FFFF;
}
.LightSkyBlue {
  color: #87CEFA;
}
.BrightYellow {
  color: #FFFF00;
}
.ElectricLime {
  color: #CCFF00;
}
.MintGreen {
  color: #00FF7F;
}
.LightOlive {
  color: #ACE5EE;
}

@media screen and (max-width: 1200px) {
  .tags,
  .about-page h1:before,
  .about-page h1:after {
    display: none;
  }
  .container.about-page .text-zone {
    top: 45%;
  }
  .slide-zone {
      grid-template-rows: 25% auto 35%;
  }
}

@media screen and (max-width: 1000px) {
  .about-page .text-zone {
    position: initial;
    width: 419px;
    transform: none;
    padding: 10px 0 10px 10px;
    box-sizing: border-box;
    margin-left: 20px;
  }
  div.container.about-page div.text-zone {
    width: 419px;
  }  
  .about-page {
    grid-template-columns: 1fr;
    grid-template-rows: auto 340px;
  }
  .about-page .slide-zone {
    grid-column-start: 1;
    grid-template-rows: 5% auto auto;
  }
}

@media screen and (max-width: 505px) {
  .container.about-page .text-zone .Im_the_greatest {
    width: 350px;
    margin-right: 5px;
  }
  .favs_box {
    padding: 0;
  }
  .favs_box_wrapper {
    background-color: inherit;
    border: none;
  }
  .favs_table {
    border: 1px solid white;
    border-radius: 10px;
    position: relative;
    left: -15px;
  }
}
